var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "stage-list",
      staticStyle: { width: "100%" },
      attrs: { id: _vm.id },
    },
    [
      _c(
        "div",
        { staticClass: "stage-label" },
        [
          _c("label", { staticClass: "mr-1" }, [
            _vm._v(_vm._s(_vm.$t("stage.field.list", [_vm.stages.length]))),
          ]),
          _c(
            "b-alert",
            {
              staticClass: "mb-0 alert-box",
              attrs: {
                dismissible: "",
                fade: "",
                show: _vm.showError,
                variant: "success",
              },
              on: { dismissed: _vm.dismissAlert },
              scopedSlots: _vm._u([
                {
                  key: "dismiss",
                  fn: function () {
                    return [
                      _c("font-awesome-icon", {
                        attrs: { icon: ["far", "check"] },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c("font-awesome-icon", { attrs: { icon: ["far", "check"] } }),
              _vm._v("   " + _vm._s(_vm.alertMsg) + " "),
            ],
            1
          ),
          !_vm.readOnly
            ? _c(
                "div",
                { staticClass: "stage-toolbar border" },
                [
                  _vm.canList("STAGE")
                    ? [
                        _c(
                          "b-btn",
                          {
                            attrs: { id: "BTN_ADD_" + _vm.id },
                            on: { click: _vm.stageAdd },
                          },
                          [
                            _c("font-awesome-icon", {
                              style: { color: "var(--grid-toolbar-button)" },
                              attrs: { icon: ["far", "plus"] },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-popover",
                          {
                            attrs: {
                              target: "BTN_ADD_" + _vm.id,
                              triggers: "hover",
                              placement: "top",
                            },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("stage.button.add")) + " "
                            ),
                          ]
                        ),
                      ]
                    : _vm._e(),
                  _c(
                    "b-btn",
                    {
                      attrs: { id: "BTN_DELETE_" + _vm.id },
                      on: { click: _vm.stageDelete },
                    },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: ["far", "trash-can"] },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-popover",
                    {
                      attrs: {
                        target: "BTN_DELETE_" + _vm.id,
                        triggers: "hover",
                        placement: "top",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("stage.button.delete")) + " ")]
                  ),
                  _c(
                    "b-btn",
                    {
                      attrs: { id: "BTN_MVUP_" + _vm.id },
                      on: { click: _vm.stageMoveUp },
                    },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: ["far", "arrow-up"] },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-popover",
                    {
                      attrs: {
                        target: "BTN_MVUP_" + _vm.id,
                        triggers: "hover",
                        placement: "top",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("stage.button.move_up")) + " ")]
                  ),
                  _c(
                    "b-btn",
                    {
                      attrs: { id: "BTN_MVDWN_" + _vm.id },
                      on: { click: _vm.stageMoveDown },
                    },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: ["far", "arrow-down"] },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-popover",
                    {
                      attrs: {
                        target: "BTN_MVDWN_" + _vm.id,
                        triggers: "hover",
                        placement: "top",
                      },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("stage.button.move_down")) + " "
                      ),
                    ]
                  ),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
      _c("ag-grid-vue", {
        staticClass: "ag-theme-balham stage-grid-height",
        staticStyle: { width: "100%" },
        attrs: {
          id: "stageGrid",
          gridOptions: _vm.gridOptions,
          columnDefs: _vm.columnDefs,
          context: _vm.context,
          defaultColDef: _vm.defaultColDef,
          overlayNoRowsTemplate: _vm.overlayNoRowsTemplate,
          overlayLoadingTemplate: _vm.overlayLoadingTemplate,
          rowData: _vm.rowData,
          rowSelection: _vm.multiple ? "multiple" : "single",
          rowMultiSelectWithClick: "",
          sideBar: false,
          suppressDragLeaveHidesColumns: "",
          suppressCellFocus: "",
          suppressScrollOnNewData: "",
          suppressContextMenu: "",
          suppressMultiSort: "",
          suppressRowClickSelection: _vm.readOnly,
        },
        on: { "grid-ready": _vm.onGridReady },
      }),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("stage.confirmation.title_delete"),
            "ok-title": _vm.$t("button.confirm"),
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: { ok: _vm.confirmDeleteOk },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var ok = ref.ok
                var cancel = ref.cancel
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: {
                        click: function ($event) {
                          return ok()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.confirm")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.confirmDeleteShow,
            callback: function ($$v) {
              _vm.confirmDeleteShow = $$v
            },
            expression: "confirmDeleteShow",
          },
        },
        [
          _c("div", { staticClass: "d-block" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t(
                    _vm.selected.length > 1
                      ? "stage.confirmation.delete_plural"
                      : "stage.confirmation.delete"
                  )
                ) +
                " "
            ),
          ]),
        ]
      ),
      _vm.stageShow
        ? _c("GenericSelectorModalForAdmin", {
            attrs: {
              show: _vm.stageShow,
              entityService: _vm.stageUtil,
              tagFilter: _vm.tagFilter,
              entity: "STAGE",
              nonAdmin: "",
            },
            on: {
              "update:show": function ($event) {
                _vm.stageShow = $event
              },
              ok: _vm.stageSuccess,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }