<template>
  <div :id="id" class="stage-list" style="width: 100%">
    <div class="stage-label">
      <label class="mr-1">{{ $t(`stage.field.list`, [stages.length]) }}</label>
      <b-alert dismissible fade :show="showError" variant="success" @dismissed="dismissAlert" class="mb-0 alert-box">
        <font-awesome-icon :icon="['far', 'check']"/>
        &nbsp;&nbsp;{{ alertMsg }}
        <template v-slot:dismiss="">
          <font-awesome-icon :icon="['far','check']"/>
        </template>
      </b-alert>
      <div v-if="!readOnly" class="stage-toolbar border">
        <template v-if="canList('STAGE')">
          <b-btn :id="`BTN_ADD_${id}`" @click="stageAdd"><font-awesome-icon :icon="['far', 'plus']" :style="{ color: 'var(--grid-toolbar-button)' }"/></b-btn>
          <b-popover :target="`BTN_ADD_${id}`" triggers="hover" placement="top">
            {{ $t('stage.button.add') }}
          </b-popover>
        </template>

        <b-btn :id="`BTN_DELETE_${id}`" @click="stageDelete"><font-awesome-icon :icon="['far', 'trash-can']"/></b-btn>
        <b-popover :target="`BTN_DELETE_${id}`" triggers="hover" placement="top">
          {{ $t('stage.button.delete') }}
        </b-popover>
        
        <b-btn :id="`BTN_MVUP_${id}`" @click="stageMoveUp"><font-awesome-icon :icon="['far','arrow-up']"/></b-btn>
        <b-popover :target="`BTN_MVUP_${id}`" triggers="hover" placement="top">
          {{ $t('stage.button.move_up') }}
        </b-popover>
        
        <b-btn :id="`BTN_MVDWN_${id}`" @click="stageMoveDown"><font-awesome-icon :icon="['far','arrow-down']"/></b-btn>
        <b-popover :target="`BTN_MVDWN_${id}`" triggers="hover" placement="top">
          {{ $t('stage.button.move_down') }}
        </b-popover>
      </div>
    </div>
    

    <ag-grid-vue style="width: 100%;" class="ag-theme-balham stage-grid-height" id="stageGrid"
          :gridOptions="gridOptions"
          @grid-ready="onGridReady"
          :columnDefs="columnDefs"
          :context="context"
          :defaultColDef="defaultColDef"
          :overlayNoRowsTemplate="overlayNoRowsTemplate"
          :overlayLoadingTemplate="overlayLoadingTemplate"
          :rowData="rowData"
          :rowSelection="multiple? 'multiple':'single'"
          rowMultiSelectWithClick
          :sideBar="false"
          suppressDragLeaveHidesColumns
          suppressCellFocus
          suppressScrollOnNewData
          suppressContextMenu
          suppressMultiSort
          :suppressRowClickSelection="readOnly"
          >
    </ag-grid-vue>

    <b-modal :title="$t('stage.confirmation.title_delete')"
        v-model="confirmDeleteShow"
        :ok-title="$t('button.confirm')"
        @ok="confirmDeleteOk"
        content-class="shadow"
        no-close-on-backdrop
        >
      <div class="d-block">
        {{ $t(selected.length > 1? 'stage.confirmation.delete_plural':'stage.confirmation.delete') }}
      </div>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="success" @click="ok()">{{ $t('button.confirm') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>

    <!-- stage selector -->
    <GenericSelectorModalForAdmin v-if="stageShow"
      :show.sync="stageShow" 
      :entityService="stageUtil"
      :tagFilter="tagFilter"
      entity="STAGE"
      nonAdmin
      @ok="stageSuccess"
    /> 
  </div>
</template>

<script>
import 'ag-grid-enterprise';
import { AgGridVue } from 'ag-grid-vue';
import { strRandom } from '@/helpers';
import DetailLinkCellRenderer from '@/components/Aggrid/CellRenderer/DetailLink';
import { taskService } from '@/services';
import { stageUtil } from '@/views/management/script/stage';
export default {
  name: 'StageList',
  components: {
    'ag-grid-vue': AgGridVue,
    'detailLinkCellRenderer': DetailLinkCellRenderer, // eslint-disable-line vue/no-unused-components
    GenericSelectorModalForAdmin : () => import('@/components/modal/GenericSelectorModalForAdmin')
  },
  props: {
    multiple: {
      type: Boolean,
      default: true
    },
    mode: {
      type: String,
      default: 'BOTH', // ['SELECT','MANAGE','BOTH']
    },
    title: {
      type: String,
      default: 'Stage Selector'
    },
    stages: {
      type: Array,
      default: () => []
    },
    holderId: {
      type: String,
      required: true
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    filterEntity: {
      type: String,
      default: null
    }
  },
  data: function() {
    return {
      id: `STAGE_LIST_${strRandom(5)}`,
      gridOptions: null,
      gridApi: null,
      columnDefs: null,
      context: null,
      defaultColDef: null,
      rowData: null,
  
      disableEdit: true,
      disableDelete: true,
      disableOk: true,
      selected: [],

      stageId: null,
      stageShow: false,
      alertMsg: null,

      confirmDeleteShow: false,
      totalRecords: 0
    };
  },
  created() {
    this.stageUtil = stageUtil;
  },
  beforeMount() {
    const self = this;
    this.gridOptions = {
      onSelectionChanged: function(event) {
        self.selected.splice(0, self.selected.length, ...(event.api.getSelectedNodes().map(i => i.data.uuId)));
        self.disableEdit = self.selected.length != 1;
        self.disableDelete = self.selected.length < 1;
        self.disableOk = (self.multiple? (self.selected.length < 1): (self.selected.length != 1));
      },
      onColumnVisible: function(event) {
        event.api.sizeColumnsToFit();
      },
      onModelUpdated: function(event) {
        // Will be called when we move the rows up/down.
        // Here, we restore the grid selections after they have been moved
        event.api.forEachNode(function(node) {
          self.selected.forEach(row => {
            if (row == node.data.uuId) {
              node.setSelected(true, false);
            }
          })
        })
      }
    };
    this.columnDefs = [
      {
        headerName: this.$t('stage.field.name'),
        field: 'name',
        checkboxSelection: () => self.readOnly != true,
        lockVisible: true,
        pinned: 'left',
        minWidth: 230,
        hide: false
      },
      {
        headerName: this.$t('stage.field.description'),
        field: 'description',
        minWidth: 100,
        hide: false
      },
    ];
    this.defaultColDef = {
      resizable: true,
      minWidth: 100,
      lockPinned: true,
      hide: true,
      menuTabs: ['columnsMenuTab']
    };
    this.context = {
      componentParent: self
    };
  },
  mounted() {
    this.$nextTick(()=>{
      window.addEventListener('orientationchange', this.detectOrientationChange);
    });
  },
  beforeDestroy() {
    window.removeEventListener('orientationchange', this.detectOrientationChange);
    this.stageUtil = null;
  },
  watch: {
    stages: function(/*newValue*/) {
      if(this.gridApi) {
        this.gridApi.setGridOption('rowData', this.stages);
        this.gridApi.refreshCells({ force: true });
      }
    },
    readOnly() {
      if (this.gridApi) {
        this.gridApi.redrawRows();
      }
    }
  },
  computed: {
    showError() {
      return this.alertMsg != null;
    },
    stageTitle() {
      return this.stageId && this.stageId.indexOf('STAGE_NEW') == -1? this.$t('stage.title_detail'): this.$t('stage.title_new');
    },
    overlayNoRowsTemplate() {
      return `<span class='grid-overlay'>${ this.$t('stage.grid.no_data') }</span>`;
    },
    overlayLoadingTemplate() {
      return `<span class='grid-overlay'><div class="mr-1 spinner-grow spinner-grow-sm text-dark"></div>${ this.$t('dataview.grid.loading') }</span>`;
    },
    tagFilter() {
      return this.filterEntity != null? { entity: this.filterEntity } : null
    }
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
      this.rowData = this.stages;
      this.detectOrientationChange();
    },
    detectOrientationChange() {
      setTimeout(() =>{
        if(this.gridApi) {
          this.gridApi.sizeColumnsToFit();
        }
      }, 100);
    },
    dismissAlert() {
      this.alertMsg = null;
    },
    stageSuccess({/**ids, */details}) {
      let have = {};
      this.rowData.map(x => have[x.uuId] = true);

      // Append any new stages to the existing list
      details.forEach(item => {
        if (!(item.uuId in have)) {
          this.rowData.push(item);
        }
      })
      this.$emit('modified', {stages: this.rowData});
    },
    stageAdd() {
      this.stageId = null;
      this.stageShow = true;
      this.alertMsg = null;
    },
    async stageDelete() {
      // If any of the stages we want to delete has tasks assigned to it, show
      // a warning.
      let tasks = await taskService.listAssignedStage(this.holderId, this.selected)
      .then(response => {
        return response.data || null;
      })
      .catch(e => {
        console.error(e); // eslint-disable-line no-console
        return null;
      });

      if (tasks == null) {
        // Something went wrong with the api, don't proceed
        console.log("Task stage check error"); // eslint-disable-line no-console
      } else if (tasks.length) {
        this.confirmDeleteShow = true;
      } else {
        // Otherwise just let it through
        this.confirmDeleteOk();
      }
    },
    stageMoveUp() {
      // Start at the top and move down. Drag up each row we encounter
      // that is also a selected row. If the selected item is already
      // at the top, move the "top" down by 1. This ensures multple
      // selections don't shift each other's positions.
      let availStart = 0;
      for (var i = 0; i < this.rowData.length; i++) {
        this.selected.forEach(item => {
          if (this.rowData[i].uuId == item) {
            if (availStart == i) {
              availStart++;
              return;
            }
            var m = this.rowData.splice(i, 1)[0];
            this.rowData.splice(Math.max(Math.max(0, i-1), availStart), 0, m);
          }
        });
      }
      this.gridApi.refreshCells({ force: true });      
      this.$emit('modified', {stages: this.rowData});
    },
    stageMoveDown() {
      // Start at the bottom and move up. Drag down each row we encounter
      // that is also a selected row. If the selected item is already
      // at the bottom, move the "bottom" down by 1. This ensures multple
      // selections don't shift each other's positions.
      let availEnd = this.rowData.length;
      for (var i = this.rowData.length-1; i >= 0; i--) {
        this.selected.forEach(item => {
          if (this.rowData[i].uuId == item) {
            if (availEnd == i) {
              availEnd--;
              return;
            }
            var m = this.rowData.splice(i, 1)[0];
            this.rowData.splice(Math.min(i+1, availEnd), 0, m);
          }
        });
      }
      this.gridApi.refreshCells({ force: true });      
      this.$emit('modified', {stages: this.rowData});
    },
    confirmDeleteOk() {
      const toDeleteIds = {};
      this.selected.map(i => toDeleteIds[i] = true);
      const keep = [];
      this.rowData.forEach(row => {
        if (!(row.uuId in toDeleteIds)) {
          keep.push(row);
        }
      });
      this.rowData = keep;
      this.$emit('modified', {stages: this.rowData});
    }
  }
}


</script>

<style lang="scss">
  .stage-list {
    .stage-toolbar {
      .btn.btn-secondary {
        background-color: transparent;
        border-color: transparent;
        padding: 2px 6px;
        margin: 8px 3px;
        border-radius: 3.2px;
        color: var(--grid-toolbar-button);

        &:focus {
          box-shadow: none;
        }
      }
      span:first-child {
        margin-left: 8px;
      }
    }

    .alert-box {
      padding: 6px 12px;
      .close {
        padding: 7px 12px;
        font-size: 21px;
      }
    } 
  }

  .stage-grid-height {
    height: 150px;
    min-height: 150px;
  }  
</style>